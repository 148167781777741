import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.card.case');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-card-case',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/component.card.case/view.scss */
.title {
  font-size: 16px;
  font-weight: bold;
  color: #9496A1;
}

.text-num {
  font-size: 23px;
  font-weight: bold;
  letter-spacing: 0px;
  margin-top: 12px;
}

.text-unit {
  margin-left: 5px;
  font: normal normal normal 16px/55px SUIT;
}

.card {
  height: 110.5px;
  border-radius: 10px;
  border: none;
}
.card:hover {
  cursor: pointer;
  box-shadow: 0px 0px 10px 0px #6758F0 !important;
}
@media (max-width: 1919px) {
  .card {
    width: 265px;
    height: 117px;
  }
}`],
})
export class ComponentCardCaseComponent implements OnInit {
    @Input() title: any;
    @Input() num: any;
    @Input() unit: any;

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
    }

    public async move() {
        this.service.href("/dashboard/device");
    }
}

export default ComponentCardCaseComponent;