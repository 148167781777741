import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.navigation');
import { Input, OnInit, OnDestroy, DoCheck } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';

@Component({
    selector: 'wiz-component-navigation',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/component.navigation/view.scss */
body {
  width: 100%;
}

.word-break {
  word-break: keep-all;
}

nav.navbar-expand-lg {
  display: flex;
  position: relative;
  max-width: 1920px !important;
  height: auto;
  flex-direction: column;
  background: #fff;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}
@media (max-width: 991px) {
  nav.navbar-expand-lg {
    display: none;
  }
}
nav.navbar-expand-lg .navbar-brand-container {
  width: 100%;
  display: flex;
  text-align: center;
  background: #fff;
  height: 80px;
}
@media (max-width: 991px) {
  nav.navbar-expand-lg .navbar-brand-container {
    display: none;
  }
}
nav.navbar-expand-lg .navbar-brand-container .navbar-brand {
  flex: auto;
}
nav.navbar-expand-lg .navbar-brand-container .navbar-brand img {
  width: 180px;
  padding-top: 22px;
}
@media (max-width: 991px) {
  nav.navbar-expand-lg .navbar-brand-container .navbar-brand img {
    display: none;
  }
}
nav.navbar-expand-lg .navbar-brand-container .navbar-toggler {
  align-items: flex-end;
  margin-left: 16px;
  margin-right: 16px;
}
nav.navbar-expand-lg .navbar-collapse {
  width: 100%;
  flex: auto;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: #fff;
}
@media (max-width: 991px) {
  nav.navbar-expand-lg .navbar-collapse {
    display: none;
  }
}
nav.navbar-expand-lg .navbar-collapse .navbar-nav {
  margin: 0;
  font-size: 14px;
  overflow-y: auto;
}
@media (max-width: 991px) {
  nav.navbar-expand-lg .navbar-collapse .navbar-nav {
    display: none;
  }
}
nav.navbar-expand-lg .navbar-collapse .navbar-nav a {
  width: 100%;
}
nav.navbar-expand-lg .navbar-collapse .navbar-nav .nav-item {
  cursor: pointer;
  font-family: "main-r";
}
@media (max-width: 991px) {
  nav.navbar-expand-lg .navbar-collapse .navbar-nav .nav-item {
    margin-left: 0;
    display: none;
  }
}
nav.navbar-expand-lg .navbar-collapse .navbar-nav .nav-item .nav-link {
  font-size: 18px;
}
nav.navbar-expand-lg .navbar-collapse .navbar-nav .nav-item.active {
  background-color: var(--wc-background);
  border-radius: 10px 0 0 10px;
}

.container-fluid {
  height: 100%;
}
.container-fluid .btn-area {
  align-items: center;
}

.login {
  width: 90px;
  height: 34px;
  border-radius: 17px;
  border: 1px solid #000000;
  background: #FFFFFF;
  font-size: 16px;
}
.login:hover {
  color: #4F40F1;
  border: 1.5px solid #4F40F1;
}

.logout {
  width: 90px;
  height: 34px;
  border-radius: 17px;
  border: 1px solid #4F41F1;
  background: #4F41F1;
  color: white;
  font-size: 16px;
  text-decoration: none;
}

.highlight {
  text-decoration: none;
  display: inline;
  box-shadow: inset 0 -15px 0 #C9C5F0;
  font-weight: bold;
  font-family: "MAIN-M";
}

.text-default {
  color: #9496A1 !important;
  font-size: 18px;
  letter-spacing: 0px;
  opacity: 1;
  font-family: "MAIN-M";
}

.mypage {
  border-radius: 17px;
  border: none;
  background: white;
  font-size: 16px;
  text-decoration: none;
}
.mypage:hover {
  color: #4F40F1;
  text-decoration: underline;
}

aside.navbar-vertical.navbar-expand-lg {
  width: 100%;
  z-index: 99999;
  padding: 0;
  border-right: none;
  height: 100%;
  flex-direction: row;
  flex: none;
}
@media (min-width: 992px) {
  aside.navbar-vertical.navbar-expand-lg {
    display: none;
  }
}
aside.navbar-vertical.navbar-expand-lg .navbar-brand-container {
  width: 100%;
  display: flex;
  text-align: center;
  background: #fff;
  height: 80px;
}
@media (max-width: 991px) {
  aside.navbar-vertical.navbar-expand-lg .navbar-brand-container {
    height: 67px;
  }
}
aside.navbar-vertical.navbar-expand-lg .navbar-brand-container .navbar-brand {
  flex: auto;
}
aside.navbar-vertical.navbar-expand-lg .navbar-brand-container .navbar-brand img {
  width: 180px;
}
@media (max-width: 991px) {
  aside.navbar-vertical.navbar-expand-lg .navbar-brand-container .navbar-brand img {
    width: 128px;
  }
}
aside.navbar-vertical.navbar-expand-lg .navbar-brand-container .navbar-toggler {
  align-items: flex-end;
  margin-left: 16px;
  margin-right: 16px;
}
aside.navbar-vertical.navbar-expand-lg .navbar-collapse {
  width: 100%;
  flex: auto;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: #fff;
}
@media (max-width: 991px) {
  aside.navbar-vertical.navbar-expand-lg .navbar-collapse {
    height: calc(100% - 56px);
    position: fixed;
    top: 56px;
    left: 0;
    overflow-y: auto;
  }
}
aside.navbar-vertical.navbar-expand-lg .navbar-collapse .navbar-nav {
  margin: 0;
  font-size: 14px;
  overflow-y: auto;
}
@media (max-width: 991px) {
  aside.navbar-vertical.navbar-expand-lg .navbar-collapse .navbar-nav {
    display: block;
  }
}
aside.navbar-vertical.navbar-expand-lg .navbar-collapse .navbar-nav a {
  width: 100%;
}
aside.navbar-vertical.navbar-expand-lg .navbar-collapse .navbar-nav .nav-item {
  cursor: pointer;
  font-family: "main-r";
}
@media (max-width: 991px) {
  aside.navbar-vertical.navbar-expand-lg .navbar-collapse .navbar-nav .nav-item {
    margin-left: 40px;
  }
}
aside.navbar-vertical.navbar-expand-lg .navbar-collapse .navbar-nav .nav-item .nav-link {
  color: #63667B;
  font-size: 18px;
  padding: 22px 32px;
}
aside.navbar-vertical.navbar-expand-lg .navbar-collapse .navbar-nav .nav-item.active {
  background-color: var(--wc-background);
  border-radius: 10px 0 0 10px;
}
aside.navbar-vertical.navbar-expand-lg .navbar-collapse .navbar-nav .nav-item.active .nav-link {
  color: var(--wc-blue);
}
aside.navbar-vertical.navbar-expand-lg .navbar-collapse .navbar-nav .dropdown.active {
  margin-bottom: 18px;
}
aside.navbar-vertical.navbar-expand-lg .navbar-collapse .navbar-nav .dropdown.active .dropdown-menu {
  display: block;
}
aside.navbar-vertical.navbar-expand-lg .navbar-collapse .navbar-nav .dropdown .dropdown-item {
  padding-left: 32px;
}
aside.navbar-vertical.navbar-expand-lg .navbar-collapse .navbar-nav .dropdown .dropdown-item.active {
  color: var(--wc-blue);
  background: linear-gradient(to right, rgba(32, 107, 196, 0.07), var(--wc-background));
}
aside.navbar-vertical.navbar-expand-lg .navbar-collapse .navbar-nav .dropdown .dropdown-item:hover {
  color: var(--wc-blue);
  background: linear-gradient(to right, rgba(32, 107, 196, 0.07), var(--wc-background));
}

.user-profile {
  padding: 28px;
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;
}
@media (max-width: 991px) {
  .user-profile {
    padding: 24px;
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
  }
}
.user-profile .card {
  box-shadow: none;
  border-right: none;
  border-left: none;
  border-radius: 0;
}
.user-profile .card .card-body {
  border-top: none !important;
}
.user-profile .card .card-footer {
  padding: 0;
  background-color: var(--wc-background);
  border-radius: 0;
}
.user-profile .clicks {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.user-profile .clicks a {
  display: block;
  width: 100%;
  color: #63667b;
  text-align: center;
}
.user-profile .clicks .click {
  cursor: pointer;
  padding: 0.75rem;
  display: inline-block;
}
.user-profile .clicks .line {
  margin: 0.75rem 0;
  border-right: 1px solid #63667b;
}
.user-profile .userinfo {
  padding: 0.25rem;
  text-align: left;
}
.user-profile .userinfo .name {
  font-family: "main-b";
  color: #2D333F;
  font-size: 20px;
}
.user-profile .userinfo .uclass,
.user-profile .userinfo .mail {
  font-size: 12px;
  color: #9097AD;
}

.notification {
  display: flex;
  align-items: center;
}
.notification i {
  font-size: 20px;
  cursor: pointer;
  position: relative;
}
.notification i span {
  position: absolute;
}
.notification .badge {
  transform: translate(0%, -100%);
}

.bg-kmbig {
  background-color: rgb(233, 233, 233);
}`],
})
export class ComponentNavigationComponent implements OnInit, OnDestroy, DoCheck {
    @Input() title = "한국한의학연구원";

    public userRole = "none";
    public show = false;
    private showNoti = false;
    private newNoti = false;

    public menu_ls = [
        { url: "/page/intro", name: "KMBIG 소개", index: "/page" },
        { url: "/dashboard/device", name: "데이터셋 대시보드", index: "dashboard" },
        { url: "/share/view/user", name: "데이터셋 공유", index: "share" },
        { url: "/dataset/search", name: "데이터셋 검색", index: "search" },
        { url: "/survey/analysis", name: "설문 분석", index: "survey" },
        { url: "/community/notice", name: "커뮤니티", index: "community" }];

    public dashboard_ls = [];
    constructor(@Inject( Service)         public service: Service,@Inject( ActivatedRoute)         public route: ActivatedRoute,@Inject( Router)         public router: Router,    ) { }

    public currentRoute: any;
    public previousRoute: any;

    public async ngOnInit() {
        await this.service.init();
        if (Object.keys(this.service.auth.session).length === 0) {
            this.menu_ls.splice(2, 1); // 데이터셋 공유
            this.menu_ls.splice(3, 1); // 설문
        } else {
            this.userRole = "user";
        }
        this.currentRoute = this.router.url;

        await this.load();
        this.previousRoute = this.currentRoute;
        await this.service.render();
    }

    public async ngDoCheck() {
        this.currentRoute = this.router.url;
        if (this.currentRoute !== this.previousRoute) {
            this.previousRoute = this.currentRoute;
            this.notify();
            this.display(false);
            this.load();
        }
    }

    public async load() {
        let { code, data } = await wiz.call("load", { "email": this.service.auth.session.email });
        if (code != 200) return;
        this.dash = data.dash;
        this.user = data.user;
        this.show = data.show;
        if (this.dashboard_ls.length === 0) {
            for (let item of this.dash) {
                this.dashboard_ls.push({ url: `/dashboard/${item.category}`, name: `${item.title} 데이터셋` })
            }
        }
        if (this.user) this.username = this.user.name;
        this.userrole = this.service.auth.session.role;
        if (this.userrole == 'admin') {
            this.menu_ls[0].url = "/page/edit/intro"
        }
        await this.notify();
        await this.service.render();
    }

    public async collapse(now) {
        this.isMenuCollapsed = !this.isMenuCollapsed;
        await this.service.render();
    }

    private highlight(index) {
        this.currentRoute = this.router.url;
        this.contain = this.currentRoute.indexOf(index);

        if (this.contain == -1) return false;
        else return true;
    }

    public menuActive(url: string) {
        let path = location.pathname;
        if (path.indexOf(url) == 0) return 'active';
        return '';
    }

    private async display(opt = null) {

        if (opt === null) {
            this.showNoti = !this.showNoti;
        }
        else {
            this.showNoti = opt;
        }
        await this.service.render();
    }

    private async notify() {

        const { code, data } = await wiz.call("noti");
        this.newNoti = data;
        await this.service.render();

    }
}

export default ComponentNavigationComponent;