import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.share.expert.detail');
import { OnInit, ViewChild, ElementRef } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Router, Event, NavigationEnd } from '@angular/router';
import ClassicEditor from "src/libs/ckeditor/ckeditor";

@Component({
    selector: 'wiz-portal-share-expert-detail',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/portal.share.expert.detail/view.scss */
.btlr {
  border-top-left-radius: 10px;
}

.btrr {
  border-top-right-radius: 10px;
}

.box-shadow {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}

.list-btn {
  border-color: #9EA0AB;
}

.btn-data {
  border-color: #8378E9;
  color: #4F41F1 !important;
}

.btn-stop {
  background-color: #F9623E;
  color: white;
  border-radius: 10px;
  border: 1px solid #F9623E;
  font-weight: bold;
  pointer-events: none;
}

.btn-allow {
  background-color: #64CF7C;
  color: white;
  border-radius: 10px;
  border: 1px solid #64CF7C;
  font-weight: bold;
}

.btn-reject {
  background-color: #F0142F;
  color: white;
  border-radius: 10px;
  border: 1px solid #F0142F;
  font-weight: bold;
}

.btn-process {
  background-color: #C9C5F0;
  color: black;
  border-radius: 10px;
  border: 1px solid #C9C5F0;
  font-weight: bold;
}

.btn-border {
  background-color: white;
  color: black;
  border-radius: 10px;
  font-weight: bold;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}

.btn-full {
  background-color: #B0B0E8;
  color: white;
  border-radius: 10px;
  font-weight: bold;
}

.info-form {
  padding: 12px 0;
  border-bottom: 1px solid rgba(110, 117, 130, 0.2);
}

.pre {
  white-space: pre;
}

.table tr td {
  border-right: 1px solid #D2D4DA;
}
.table tr th {
  border-right: 1px solid #D2D4DA;
  border-top: 1px solid #D2D4DA;
}
.table tr td:first-child {
  border-left: 1px solid #D2D4DA;
}
.table tr th:first-child {
  border-left: 1px solid #D2D4DA;
}
.table td {
  width: 16.6%;
}

input[type=checkbox] {
  border-radius: 5px !important;
}

input[type=checkbox]:checked {
  background: #C9C5F0;
}

.btn-five {
  background-color: #F0142F;
  color: white;
  border-radius: 50%;
  border: 1px solid #F0142F;
  font-weight: bold;
  pointer-events: none;
  box-shadow: 0 3px 6px darkslategray;
  width: 100px;
  height: 100px;
  font-size: 1.5rem;
}

.btn-four {
  background-color: #F43F18;
  color: white;
  border-radius: 50%;
  border: 1px solid #F43F18;
  font-weight: bold;
  pointer-events: none;
  box-shadow: 0 3px 6px darkslategray;
  width: 100px;
  height: 100px;
  font-size: 1.5rem;
}

.btn-three {
  background-color: #F9623E;
  color: white;
  border-radius: 50%;
  border: 1px solid #F9623E;
  font-weight: bold;
  pointer-events: none;
  box-shadow: 0 3px 6px darkslategray;
  width: 100px;
  height: 100px;
  font-size: 1.5rem;
}

.btn-two {
  background-color: #FFBA00;
  color: white;
  border-radius: 50%;
  border: 1px solid #FFBA00;
  font-weight: bold;
  pointer-events: none;
  box-shadow: 0 3px 6px darkslategray;
  width: 100px;
  height: 100px;
  font-size: 1.5rem;
}

.btn-one {
  background-color: #64CF7C;
  color: white;
  border-radius: 50%;
  border: 1px solid #64CF7C;
  font-weight: bold;
  pointer-events: none;
  box-shadow: 0 3px 6px darkslategray;
  width: 100px;
  height: 100px;
  font-size: 1.5rem;
}

.survey .btn {
  border-radius: 0;
}
.survey .container-tab {
  width: 100%;
  padding: 0;
  overflow-x: auto;
}
.survey .nav-tabs {
  align-items: center;
}
.survey .nav-link {
  width: fit-content;
}
.survey .check {
  color: white;
  border-bottom: 1px solid #6967E8;
  background-color: #C9C5F0;
}

.loading-wrap {
  display: flex;
  position: absolute;
  inset: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.08);
}

.offcanvas {
  width: auto;
  max-width: 32%;
}`],
})
export class PortalShareExpertDetailComponent implements OnInit {

    public formID: any;
    public info = {
        id: "",
        user_id: "",
        user: "",
        category: "",
        title: "",
        dataset_id: "",
        dataset_name: "",
        purpose: "",
        status: "",
        expert_status: "",
        status_class: "",
        status_name: "",
        created: "",
        updated: "",
        deadline: "",
        comment: ""
    };
    public preview = {
        df: "",
        graph: "",
        schema: ""
    }
    public detail: any;
    public conditions = [
        { title: "신장 소숫점 자리 제거", en: "point" },
        { title: "몸무게 소숫점 자리 제거", en: "mmi" },
        { title: "성별 제거", en: "sex" },
        { title: "연령대 제거", en: "agegroup" },
        { title: "신장 제거", en: "height" },
        { title: "몸무게 제거", en: "weight" }]
    public conFilter = {
        point: "", sex: "", agegroup: "", height: "", weight: "", mmi: ""
    };

    @ViewChild('editor')
    public editorElement: ElementRef;

    public editor: any;
    public editorLoaded: boolean = false;

    public expertID: any;

    public period = [];
    public sex = ["여자", "남자"];
    public age = ["20-", "20-29", "30-39", "40-49", "50-59", "60+"];
    public sasang = ["태양인", "태음인", "소양인", "소음인"];
    public weight1: any;
    public weight2: any;
    public height1: any;
    public height2: any;
    public filter = {
        period: {},
        age: {},
        sex: {},
        sasang: {},
        height1: "",
        height2: "",
        weight1: "",
        weight2: "",
    };
    public previewTF: boolean = true;

    public ruk = {
        ut_num: "", ut_class: "", rs_num: "", rs_class: "", anonymity: ""
    }

    constructor(@Inject( Service)         public service: Service,@Inject( Router    )         private router: Router    ) { }

    public async ngOnInit() {
        let init = await this.service.init();
        if (Object.keys(init.auth.session).length === 0 || init.auth.session.is_expert == 0) {
            this.service.href(`/`);
            return;
        }
        this.expertID = init.auth.session.id;
        await this.loading(true);
        await this.title();
        await this.load();
        await this.loading(false);

        this.router.events.subscribe(async (event: Event) => {
            if (event instanceof NavigationEnd) {
                this.currentRoute = event.url;
                if (this.currentRoute.includes("/share/view/expert")) {
                    // await this.load();
                }
            }
        })
        await this.service.render();
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public titles: any;
    public surName: any;
    public req: any;

    public async title() {
        this.formID = WizRoute.segment.id;
        const { data } = await wiz.call("titles", { id: this.formID });
        this.titles = data.titles
        this.req = data.require
        if (this.req != 'lifelog' && this.req !== 'survey') {
            this.surName = 'none';
        } else this.surName = this.titles[0];
        await this.service.render();
    }

    public async surveyInfo(name) {
        this.surName = name;
        await this.service.render();

        await this.loading(true);
        const { code, data } = await wiz.call("surveyInfo", { name: this.surName, id: this.formID });
        this.preview = data
        await this.service.render();
        await this.loading(false);

        this.columns = [];
        for (let value of this.preview.df) {
            for (let key in value) {
                if (!this.columns.includes(key)) {
                    this.columns.push(key);
                }
            }
        }
        await this.service.render();

        if (this.preview.df.length == 0) {
            this.previewTF = false;
        }

    }

    public async load() {
        let { code, data } = await wiz.call("load", { id: this.formID, surName: this.surName });
        if (code == 300) {
            this.service.href(`/share/expert/form`);
            return;
        }
        this.info = data.info;

        this.info.created = this.info.created.substring(0, 10);
        if (this.info.filter) {
            this.filter = JSON.parse(this.info.filter.replace(/'/g, '"').replace(/True/g, 'true').replace(/False/g, 'false'))
        }
        if (this.info.condition) {
            this.conFilter = JSON.parse(this.info.condition.replace(/'/g, '"').replace(/True/g, 'true').replace(/False/g, 'false'));
        };
        this.preview = data.preview;
        this.period = this.preview['period']
        this.preview.schema = JSON.parse(data.preview.schema)
        if (this.preview.df.length == 0) {
            this.previewTF = false;
        } else {
            this.previewTF = true;
            this.columns = [];
            for (let value of this.preview.df) {
                for (let key in value) {
                    if (!this.columns.includes(key)) {
                        this.columns.push(key);
                    }
                }
            }
            if (this.info.datatype == '기기') {
                this.detail = this.columns.map((col) => {
                    let item = this.preview.schema.find((item) => item.name === col);
                    return item?.desc || "";
                });
            }
        }

        await this.service.render();
        if ((['allow', 'reject', 'stop'].includes(this.info.expert_status) && this.info.comment != '') || this.info.expert_status == 'process') {
            await this.buildEditor();
        }

        this.ruk = data.ruk;
        await this.service.render();
    }

    public opening = false;

    public async dataopen() {
        this.opening = true;
        await this.service.render();
    }

    public async close() {
        this.opening = false;
        this.service.render();
    }


    // 데이터셋 정보(상세 설명) 페이지 이동
    public async open() {
        this.url = "https://kmbig-v4.seasonsoft.net/dashboard/device/" + this.info.dataset_id;
        window.open(this.url)
    }

    // 공유 활용 거절
    public async reject() {
        let comment = this.editor.data.get();
        let res = await this.alert(this.info.dataset_name + " 활용 요청을 거절하시겠습니까?", "error", "취소");
        if (res) {
            await this.loading(true);
            let { code, data } = await wiz.call("update", { form: this.formID, comment: comment, status: "reject" });
            if (code == 200) {
                await this.loading(false);
                location.reload();
            }
        }
    }

    // 공유 활용 승인
    public async allow() {
        let comment = this.editor.data.get();
        let res = await this.alert(this.info.dataset_name + " 활용 요청을 승인하시겠습니까?", "success", "취소");
        if (res) {
            await this.loading(true);
            let { code, data } = await wiz.call("update", { form: this.formID, comment: comment, status: "allow" });
            if (code == 200) {
                await this.loading(false);
                location.reload();
            }
        }
    }

    public isEditable() {
        if (['allow', 'reject', 'stop'].includes(this.info.expert_status)) {
            return false;
        } else {
            return true;
        }
    }

    public async buildEditor() {
        this.editorLoaded = false;
        await this.service.render();
        this.editorLoaded = true;
        await this.service.render();
        let editor = this.editor = await ClassicEditor.create(this.editorElement.nativeElement, {
            toolbar: {
                shouldNotGroupWhenFull: true
            },
            link: {
                addTargetToExternalLinks: true,
                defaultProtocol: 'https://',
            },
            removePlugins: ["MediaEmbedToolbar", "Markdown"],
            table: ClassicEditor.defaultConfig.table,
            simpleUpload: {
                uploadUrl: 'files/expert/upload/dataset/' + this.info.id + '/' + this.info.expert + '/' + this.info.dataset_id
            }
        });

        if (!this.isEditable()) {
            const toolbarElement = editor.ui.view.toolbar.element;
            toolbarElement.style.display = 'none';
            editor.isReadOnly = true;
        }
        this.editor.data.set(this.info.comment);
    }

    private async loading(act) {
        this._loading = act;
        await this.service.render();
    }
}

export default PortalShareExpertDetailComponent;