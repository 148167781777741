import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.dashboard');
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute, Event, NavigationEnd } from '@angular/router';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-dashboard',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/page.dashboard/view.scss */
.container-full {
  height: 100%;
  overflow: auto;
}`],
})
export class PageDashboardComponent implements OnInit {
    public mode = '';

    constructor(@Inject( Service)         public service: Service,@Inject( Router)         private router: Router,@Inject( ActivatedRoute)         private route: ActivatedRoute,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.setting();
        this.router.events.subscribe(async (event: Event) => {
            if (event instanceof NavigationEnd) {
                await this.setting();
            }
        })
    }

    public async setting() {
        this.mode = WizRoute.segment.mode;
        await this.service.render();
    }
}

export default PageDashboardComponent;