import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.share.user.detail');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Router, Event, NavigationEnd } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'wiz-portal-share-user-detail',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/portal.share.user.detail/view.scss */
.btlr {
  border-top-left-radius: 10px;
}

.btrr {
  border-top-right-radius: 10px;
}

.box-shadow {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}

.btn-border {
  background-color: white;
  color: black;
  border-radius: 10px;
  font-weight: bold;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}

.btn-request {
  background-color: #E2E4F0;
  color: var(--wiz-color-navy);
  border-radius: 10px;
  border: 1px solid #E2E4F0;
  font-weight: bold;
}

.btn-allow {
  background-color: #64CF7C;
  color: white;
  border-radius: 10px;
  border: 1px solid #64CF7C;
  font-weight: bold;
}

.btn-reject {
  background-color: #F0142F;
  color: white;
  border-radius: 10px;
  border: 1px solid #F0142F;
  font-weight: bold;
}

.btn-process {
  background-color: #C9C5F0;
  color: black;
  border-radius: 10px;
  border: 1px solid #C9C5F0;
  font-weight: bold;
}

.btn-down {
  background: #4F40F1;
  border-radius: 34px;
  color: white;
}

.list-btn {
  border-color: #9EA0AB;
}

.btn-data {
  border-color: #8378E9;
  color: #4F41F1;
}

.btn-form {
  border: none;
  color: #4F40F1;
  background-color: white;
}

.info-form {
  padding: 12px 0;
  border-bottom: 1px solid rgba(110, 117, 130, 0.2);
}
.info-form input {
  border-radius: 0;
}
.info-form h4 {
  margin-top: 9px;
}

.info-form.first-child {
  border-top: 2px solid #000000;
}

.nav-tabs.nav-fill .nav-link {
  cursor: pointer;
  background: #F7F7F8;
  color: #777986;
  font-size: 20px;
}
.nav-tabs.nav-fill .nav-link.active {
  border-bottom: solid 2px #4F41F1;
  background: white;
  color: #4F41F1;
  font-size: 20px;
  text-align: center;
}

.table tr td {
  border-right: 1px solid #D2D4DA;
}
.table tr th {
  border-right: 1px solid #D2D4DA;
  border-top: 1px solid #D2D4DA;
  background-color: #C9C5F0;
  color: black;
  font-size: 14px;
}
.table tr td:first-child {
  border-left: 1px solid #D2D4DA;
}
.table tr th:first-child {
  border-left: 1px solid #D2D4DA;
}
.table td {
  width: 16.6%;
}

input[type=checkbox] {
  border-radius: 5px !important;
}

input[type=checkbox]:checked {
  background: #C9C5F0;
}

iframe {
  min-height: 1000px;
}`],
})
export class PortalShareUserDetailComponent implements OnInit {

    public docId: any;
    public info = {
        id: "",
        user_id: "",
        user: "",
        category: "",
        title: "",
        dataset_id: "",
        dataset_name: "",
        purpose: "",
        status: "",
        status_class: "",
        status_name: "",
        created: "",
        updated: "",
        deadline: "",
        down_period: "",
        datatype: ""
    };
    public userID: any;

    public period = [2020, 2021, 2022, 2023];
    public sex = ["여자", "남자"];
    public age = ["20-", "20-29", "30-39", "40-49", "50-59", "60+"];
    public sasang = ["태양인", "태음인", "소양인", "소음인"];
    public weight1: any;
    public weight2: any;
    public height1: any;
    public height2: any;
    public filter = {
        period: {},
        age: {},
        sex: {},
        height1: "",
        height2: "",
        weight1: "",
        weight2: "",
    };

    public conditions = [
        { title: "신장 소숫점 자리 제거", en: "point" },
        { title: "몸무게 소숫점 자리 제거", en: "mmi" },
        { title: "성별 제거", en: "sex" },
        { title: "연령대 제거", en: "agegroup" },
        { title: "신장 제거", en: "height" },
        { title: "몸무게 제거", en: "weight" }
    ]
    public conFilter = {
        point: "", sex: "", agegroup: "", height: "", weight: "", mmi: "", detail: "",
    };

    public oneMonthAfter: Date;
    public nowDate: Date;

    constructor(@Inject( Service)         public service: Service,@Inject( Router)         private router: Router,@Inject( DomSanitizer)         private sanitizer: DomSanitizer,    ) { }

    public async ngOnInit() {
        await this.service.init();
        this.userID = this.service.auth.session.id;

        await this.load();

        this.router.events.subscribe(async (event: Event) => {
            if (event instanceof NavigationEnd) {
                this.currentRoute = event.url;
                if (this.currentRoute.includes("/share/user/form/view")) {
                    await this.load();
                }
            }
        })
        await this.service.render();
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async load() {
        this.docId = WizRoute.segment.id;
        let { code, data } = await wiz.call("load", { id: this.docId });
        if (code == 300) {
            this.service.href(`/share/user/form`);
            return;
        }
        this.info = data;
        this.previewFile = data['file'];
        this.nowDate = new Date();
        const updated = this.info.updated.split(' ')[0];
        const date: Date = new Date(updated);
        this.oneMonthAfter = new Date(date);
        switch (this.info.down_period) {
            case '하루':
                this.oneMonthAfter.setDate(date.getDate() + 1);
                break;
            case '일주일':
                this.oneMonthAfter.setDate(date.getDate() + 7);
                break;
            case '한달':
                this.oneMonthAfter.setMonth(date.getMonth() + 1);
                break;
            case '6개월':
                this.oneMonthAfter.setMonth(date.getMonth() + 6);
                break;
            case '1년':
                this.oneMonthAfter.setFullYear(date.getFullYear() + 1);
                break;
            default:
                console.log('Invalid drive period');
                return;
        }

        if (this.info.requirement) {
            if (this.info.requirement == "raw") {
                this.info.requirement = "Raw 데이터";
                this.period.push(2022)
            }
            else if (this.info.requirement == "cleansing") this.info.requirement = "정제 데이터"
            else if (this.info.requirement == "synthetic") this.info.requirement = "재현 데이터"
        }
        if (this.info.filter) {
            this.filter = JSON.parse(this.info.filter.replace(/'/g, '"').replace(/True/g, 'true').replace(/False/g, 'false'));
        };
        if (this.info.condition) {
            this.conFilter = JSON.parse(this.info.condition.replace(/'/g, '"').replace(/True/g, 'true').replace(/False/g, 'false'));
        };

        await this.service.render();
    }

    public async download() {
        if (this.nowDate > this.oneMonthAfter) {
            await this.alert("유효기간이 만료되어 저장할 수 없습니다. 다시 공유 요청 해주십시오.")
        }
        else {
            let download = wiz.url('download?id=' + this.info.dataset_id + "&title=" + this.info.dataset_name + "&docID=" + this.docId)
            window.location.href = download;
        }
    }

    public async drive() {

        if (this.nowDate > this.oneMonthAfter) {
            await this.alert("유효기간이 만료되어 저장할 수 없습니다. 다시 공유 요청 해주십시오.")
        }
        else {
            let res = await this.alert(this.info.dataset_name + " 데이터셋을 연구자 서랍에 저장하시겠습니까?", "success", "취소");
            if (res) {
                let { code, data } = await wiz.call("drive", { "id": this.info.dataset_id, "name": this.info.dataset_name, "docID": this.docId });
                if (code == 200) {
                    await this.alert("저장되었습니다.", "success");
                }
            }
        }
    }

    public async move() {
        let url = "/dashboard/deivce/" + this.info.dataset_id;
        window.open(url);
    }

    public tab = "info";

    public async change(tab) {
        this.tab = tab;
        this.service.render();
    }

    public async downloadF(docID) {
        let download = wiz.url("downloadF?docID=" + docID)
        window.open(download, '_blank');
    }


}

export default PortalShareUserDetailComponent;