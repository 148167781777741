import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.dataset.graph');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Router, Event, NavigationEnd } from '@angular/router';

@Component({
    selector: 'wiz-component-dataset-graph',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/component.dataset.graph/view.scss */
.card {
  background: #EDEEF8;
  box-shadow: 0px 3px 24px #E3E6EC;
  border-radius: 10px;
  opacity: 1;
  width: 1460px;
  height: 678px;
  margin-top: 30px;
  border: none;
}
.card img {
  position: relative;
  margin: auto;
}
.card .content {
  position: absolute;
  max-width: 1460px;
  width: 100%;
}
.card .content .badge {
  margin: auto;
  max-width: 300px;
  width: 100%;
  height: 45px;
  align-content: center;
  font-size: 25px;
}
.card .content .top {
  width: 1460px;
  height: 450px;
}
.card .content .top .title {
  text-align: center;
}
.card .content .top .device {
  color: white;
  background-color: #4E3FEE;
}
.card .content .top .link {
  color: white;
  background-color: black;
}
.card .content .bottom {
  margin-top: 90px;
  justify-content: center;
}
.card .content .bottom .survey {
  color: white;
  background-color: #E56E85;
}
.card .content .dataset {
  max-width: 200px;
  text-align: center;
  padding: 0;
}
.card .content .btn {
  max-width: 180px;
  width: 100%;
  border-radius: 15px;
}
.card .content .de-line {
  border: 1px solid #4E3FEE;
}
.card .content .link-line {
  border: 1px solid black;
}
.card .content .sur-line {
  border: 1px solid #E56E85;
}`],
})
export class ComponentDatasetGraphComponent implements OnInit {

    constructor(@Inject( Service)         public service: Service,@Inject( Router    )         public router: Router    ) { }
    public devices = [];
    public links = [];
    public survey = [];

    public async ngOnInit() {
        await this.service.init();
        await this.load();
        await this.service.render();
    }

    public async load() {
        const { code, data } = await wiz.call("load");
        this.links = data.links;
        this.survey = data.survey;
        this.devices = data.devices;
    }

    public async route(category, id) {
        if (category != 'link') {
            await this.service.href(`/dashboard/device/${id}`);
        } else {
            window.open(id);
        }
        await this.service.render();
    }

}

export default ComponentDatasetGraphComponent;