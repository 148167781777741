import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.footer');
import { OnInit, Input } from '@angular/core';

@Component({
    selector: 'wiz-component-footer',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/component.footer/view.scss */
.container-footer {
  width: 100%;
  background-color: #E2E4F1;
}
.container-footer .d-flex {
  align-items: center;
  justify-content: center;
}
@media (max-width: 900px) {
  .container-footer .d-flex {
    flex-wrap: wrap;
  }
}
.container-footer img {
  width: 100%;
  max-width: 150px;
}
.container-footer .text {
  font-weight: bold;
}`],
})
export class ComponentFooterComponent implements OnInit {
    @Input() title: any;

    public async ngOnInit() {
    }
}

export default ComponentFooterComponent;