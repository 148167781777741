import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.community.comment');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import toastr from 'toastr';

@Component({
    selector: 'wiz-portal-community-comment',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/portal.community.comment/view.scss */
textarea {
  resize: none;
}

.btn-del {
  background: none;
  color: red;
  border: none;
  box-shadow: none;
}
.btn-del:hover {
  border-color: black;
}

.btn-re {
  background: none;
  border: none;
  box-shadow: none;
}`],
})
export class PortalCommunityCommentComponent implements OnInit {
    private comment = [];
    private login = {};
    private post = { id: "", content: "" };
    private text = "";
    private debouncer = new Subject<string>();

    constructor(@Inject( Service) public service: Service) {
        this.debouncer.pipe(debounceTime(30)).subscribe(() => this.upload());
    }

    public async ngOnInit() {
        await this.service.init();
        this.load();
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    private async load() {
        this.post.id = WizRoute.segment.id;
        let { code, data } = await wiz.call('load', { board_id: this.post.id });

        const { comment, login } = data;
        this.comment = comment;
        this.login = login;

        await this.service.render();
    }

    private async onEnter() {
        this.debouncer.next();
    }

    private async upload() {
        if (Object.keys(this.service.auth.session).length === 0) {
            toastr.error("로그인해주세요");
            return;
        }
        if (this.text == "") {
            toastr.warning("내용을 입력해주세요");
            return;
        }

        this.post.content = this.text;
        let { code } = await wiz.call('upload', this.post);

        if (code != 200) {
            await this.alert("잘못된 접근입니다.", "error");
        }
        this.text = '';

        let el = document.getElementsByClassName('form-control');
        for(let i = 0; i < el.length; i++) {
            el[i].value = "";
        }

        await this.load();
    }

    private async del(id: number) {
        let res = await this.alert("댓글을 삭제하겠습니까?", "error");

        if (!res)
            return;

        let { code, data } = await wiz.call('delete', { id });
        await this.load();
    }

    private async update(id: number, text: string) {
        let { code, data } = await wiz.call('update', { id, text })
        await this.load();
    }
}

export default PortalCommunityCommentComponent;