import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.main');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-main',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/page.main/view.scss */
.container-full {
  width: 100%;
  height: 100%;
}
.container-full .container-sub {
  width: 100%;
  background: white;
}
.container-full .container-sub .container-search {
  max-width: 1904px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: white;
  height: 160px;
  margin: auto;
}
@media (max-width: 768px) {
  .container-full .container-sub .container-search {
    width: 100%;
    height: 100px;
  }
}
.container-full .container-sub .container-search .search {
  max-width: 1904px;
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 30px;
}
@media (max-width: 768px) {
  .container-full .container-sub .container-search .search {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
  }
}
.container-full .container-sub2 {
  width: 100%;
  background: #F7F7FA;
  overflow-x: auto;
}
.container-full .container-sub2 .container-dataset {
  display: flex;
  background: #F7F7FA;
  max-width: 1920px;
  margin: auto;
}
@media (max-width: 1919px) {
  .container-full .container-sub2 .container-dataset {
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
.container-full .container-sub2 .container-dataset .container-network {
  max-width: 1460px;
  margin-left: 70px;
}
@media (max-width: 1919px) {
  .container-full .container-sub2 .container-dataset .container-network {
    width: 100%;
    margin: 0px;
  }
}
.container-full .container-sub2 .container-dataset .container-card {
  flex-direction: column;
  width: 290px;
  height: 100%;
  margin: 30px 0 30px 30px;
}
@media (max-width: 1919px) {
  .container-full .container-sub2 .container-dataset .container-card {
    justify-content: center;
    flex-direction: row;
    width: 100%;
    height: 150px;
    margin: 30px;
    align-items: center;
  }
}
@media (max-width: 1414px) {
  .container-full .container-sub2 .container-dataset .container-card {
    justify-content: flex-start;
  }
}
.container-full .container-sub2 .container-dataset .container-card .case {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
}
.container-full .container-sub2 .container-dataset .container-card .case .d-flex {
  margin-bottom: 10px;
}
@media (max-width: 1919px) {
  .container-full .container-sub2 .container-dataset .container-card .case {
    flex-direction: row;
    margin: 0 20px;
  }
  .container-full .container-sub2 .container-dataset .container-card .case .d-flex {
    margin: 0;
  }
  .container-full .container-sub2 .container-dataset .container-card .case .last {
    margin-left: 20px;
  }
}`],
})
export class PageMainComponent implements OnInit {
    private info = [];

    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        const { code, data } = await wiz.call("load_card");
        if (code == 200) {
            this.info = data;
        }
        await this.fileinfo();
    }

    public async fileinfo() {
        const { code, data } = await wiz.call("data_load");
        this.info.rawinfo = data;

    }
}

export default PageMainComponent;