import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.dashboard.link');
import { Service } from "src/libs/portal/season/service";
import { Router, Event, NavigationEnd } from '@angular/router';

@Component({
    selector: 'wiz-portal-dashboard-link',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/portal.dashboard.link/view.scss */
.container-full {
  min-width: 1920px;
  height: 100%;
  overflow: auto;
}
.container-full .container-sub {
  width: 100%;
  background: no-repeat center/100% url("/assets/images/dashboard/bubble.png");
}
.container-full .container-sub .container-image {
  width: 1780px;
  height: 400px;
  margin-left: auto;
  margin-right: auto;
}
.container-full .container-sub .container-image .content {
  height: 100%;
  width: 100%;
}
.container-full .container-sub .container-image .content .content-menu {
  padding-top: 112px;
}
.container-full .container-sub .container-image .content .content-menu-name {
  padding-top: 15px;
}
.container-full .container-sub .container-image .content .content-content {
  padding-top: 15px;
  width: 922px;
}
.container-full .container-sub .container-image .content .content-content .red {
  color: red;
}
.container-full .container-sub2 {
  width: 100%;
  background: #F7F7FA;
}
.container-full .container-sub2 .container-dataset {
  background: #F7F7FA;
  position: relative;
  max-width: 1780px;
  margin: auto;
}
.container-full .container-sub2 .container-dataset .card-container {
  position: relative;
}
.container-full .container-sub2 .container-dataset .card {
  border-radius: 0 0 15px 15px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  position: absolute;
  z-index: 10000;
}
.container-full .container-sub2 .container-dataset .card span {
  font-size: 15px;
  font-weight: bold;
}
.container-full .container-sub2 .container-dataset .open {
  border-radius: 15px 15px 0 0;
}
.container-full .container-sub2 .container-dataset button {
  width: 100%;
  margin: auto;
  height: 80px;
  border-radius: 15px;
  font-size: 25px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.container-full .container-sub2 .container-dataset button:hover {
  border: 1px solid #4F40F1;
  color: #4F40F1;
}

.text-menu {
  color: #5B5B6D;
  font-size: 16px;
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: none;
}

.text-menu-name {
  color: black;
  font-size: 52px;
  font-weight: bold;
  font-family: "MAIN-B";
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: none;
}

.text-content {
  color: #404252;
  font-size: 22px;
  line-height: 180%;
  word-break: keep-all;
}

.btn-data {
  border-radius: 999px;
  background: #4F40F1;
  font-family: "MAIN-M";
  color: #FFFFFF;
  width: 166px;
  height: 40px;
  border: 0px;
}

.text-menu {
  color: #5B5B6D;
  font-size: 16px;
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: none;
}

.text-menu-name {
  color: black;
  font-size: 52px;
  font-weight: bold;
  font-family: "dev-B";
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: none;
}

.text-content {
  color: #404252;
  font-size: 22px;
  line-height: 180%;
}`],
})
export class PortalDashboardLinkComponent implements OnInit {

    constructor(@Inject( Service)         public service: Service,@Inject( Router    )         public router: Router    ) { }

    public linkLS = [];
    public tab = '';
    public categoryLs = {
        id: "",
        category: "",
        content: "",
        title: ""
    };

    public async ngOnInit() {
        await this.service.init();
        await this.load();
        await this.info();
        await this.service.render();
    }

    public async load() {
        let { code, data } = await wiz.call("load", { "id": "링크 연계" });
        this.categoryLs = data.dashboard_row;
        await this.service.render();
    }

    public async info() {
        const { code, data } = await wiz.call("info");
        this.linkLS = data;
    }

    public async open(index) {
        this.tab = this.tab === index ? null : index;
        await this.service.render();
    }
}

export default PortalDashboardLinkComponent;