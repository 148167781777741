import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.dashboard.device');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Router, Event, NavigationEnd } from '@angular/router';

@Component({
    selector: 'wiz-portal-dashboard-device',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/portal.dashboard.device/view.scss */
.container-full {
  min-width: 1920px;
  height: 100%;
  overflow: auto;
}
.container-full .container-sub {
  width: 100%;
  background: no-repeat center/100% url("/assets/images/dashboard/bubble.png");
}
.container-full .container-sub .container-image {
  width: 1780px;
  height: 480px;
  margin-left: auto;
  margin-right: auto;
}
.container-full .container-sub .container-image .content {
  height: 100%;
  width: 100%;
}
.container-full .container-sub .container-image .content .content-menu {
  padding-top: 112px;
}
.container-full .container-sub .container-image .content .content-menu-name {
  padding-top: 15px;
}
.container-full .container-sub .container-image .content .content-content {
  padding-top: 30px;
  width: 922px;
}
.container-full .container-sub .container-image .content .content-button {
  margin-top: 30px;
}
.container-full .container-sub .container-image .content .content-button .btn-data {
  border-radius: 999px;
  background: #4F40F1;
  font-family: "MAIN-M";
  color: #FFFFFF;
  width: 166px;
  height: 40px;
  border: 0px;
}
.container-full .container-sub .container-image .dataset-menu {
  height: 100%;
  width: 845px;
  padding-top: 66px;
  padding-left: 85px;
  position: relative;
  flex: 1;
  overflow-y: auto;
}
.container-full .container-sub .container-image .dataset-menu .menu-card {
  width: 142px;
  height: 63px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 3px 24px rgba(16, 18, 35, 0.0431372549);
  text-align: center;
  vertical-align: middle;
  line-height: 63px;
  font-size: 16px;
  overflow: hidden;
  margin-right: 10px;
  margin-bottom: 10px;
  display: inline-block;
}
.container-full .container-sub .container-image .dataset-menu .menu-card:hover {
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.container-full .container-sub2 {
  width: 100%;
  background: #F7F7FA;
}
.container-full .container-sub2 .container-dashboard {
  background: #F7F7FA;
  opacity: 1;
  max-width: 1780px;
  margin: auto;
}
.container-full .container-sub2 .container-dashboard .pie-chart {
  margin-top: 40px;
  width: 450px;
  height: 567px;
}
.container-full .container-sub2 .container-dashboard .situation-bar {
  width: 1320px;
  height: 567px;
  margin-left: 25px;
  margin-top: 40px;
  background: #D8D8E6;
  box-shadow: 0px 3px 24px #E3E6EC;
  border-radius: 10px;
}
@media (max-width: 970px) {
  .container-full .container-sub2 .container-dashboard .situation-bar {
    width: 1300px;
  }
}
.container-full .container-sub2 .container-dashboard .intersection-chart {
  margin-top: 30px;
  width: 450px;
  height: 535px;
}
.container-full .container-sub2 .container-dashboard .line-chart {
  margin-left: 15px;
  margin-top: 30px;
  width: 1330px;
  height: 535px;
}
@media (max-width: 970px) {
  .container-full .container-sub2 .container-dashboard .line-chart {
    width: 1300px;
  }
}
.container-full .container-sub2 .container-dashboard .card {
  border-radius: 10px;
}
.container-full .container-sub2 .container-dashboard .analysis {
  width: 1790px;
  min-height: 535px;
  margin-top: 30px;
  background: white;
  margin-left: 10px;
}
.container-full .container-sub2 .container-dashboard .analysis .doughnut-chart {
  width: 850px;
  height: 535px;
}
.container-full .container-sub2 .container-dashboard .analysis .card {
  border-radius: 10px;
}
.container-full .container-sub2 .container-dashboard .analysis .chart-base {
  width: 938px;
  height: 535px;
  background: #D8D8E6;
}
@media (max-width: 970px) {
  .container-full .container-sub2 .container-dashboard .analysis .chart-base {
    width: 936px;
  }
}
.container-full .container-sub2 .container-dashboard .analysis .chart-base .card {
  border-radius: 10px;
}
.container-full .container-sub2 .container-dashboard .analysis .chart-base .bar-chart-right {
  width: 890px;
  height: 486px;
  margin-top: 22px;
  margin-left: 22px;
  background: white;
}
.container-full .container-sub2 .container-dashboard .analysis2 {
  width: 1790px;
  max-height: 535px;
  margin-top: 30px;
  margin-left: 10px;
  background: #D8D8E6;
  border-radius: 10px;
}
.container-full .container-sub2 .container-dashboard .analysis2 .bar-chart-right {
  width: 620px;
  height: 487px;
  margin-top: 22px;
  margin-left: 22px;
  background: white;
}
.container-full .container-sub2 .container-dashboard .analysis2 .constitution {
  width: 588px;
  height: 534px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.container-full .container-sub2 .container-dashboard .analysis2 .constitution .card.constitution-card {
  width: 222px;
  height: 222px;
  background: #4F40F1;
  margin-top: 15px;
  margin-left: 30px;
}
.container-full .container-sub2 .container-dashboard .analysis2 .constitution .card.constitution-card .card.constitution-card2 {
  width: 102px;
  height: 102px;
  background: white;
  margin-left: auto;
  margin-right: auto;
  margin-top: 53px;
}
.container-full .container-sub2 .container-dashboard .analysis2 .constitution .card.constitution-card .card.constitution-card2 img {
  width: 76px;
  height: 83px;
  margin: auto;
}
.container-full .container-sub2 .container-dashboard .analysis2 .constitution .card.constitution-card3 {
  width: 222px;
  height: 222px;
  background: white;
  margin-top: 15px;
  margin-left: 20px;
}
.container-full .container-sub2 .container-dashboard .analysis2 .constitution .card.constitution-card3 .card.constitution-card4 {
  width: 102px;
  height: 102px;
  background: white;
  margin-left: auto;
  margin-right: auto;
  margin-top: 53px;
}
.container-full .container-sub2 .container-dashboard .analysis2 .constitution .card.constitution-card3 .card.constitution-card4 img {
  width: 76px;
  height: 83px;
  margin: auto;
}
.container-full .container-sub2 .container-dashboard .analysis2 .card {
  border-radius: 10px;
}
.container-full .container-sub2 .container-dashboard .analysis2 .chart-base2 {
  margin-left: 20px;
  max-width: 1126px;
  max-height: 535px;
  background: white;
  border-radius: 10px;
}
@media (max-width: 970px) {
  .container-full .container-sub2 .container-dashboard .analysis2 .chart-base2 {
    width: 1124px;
  }
}
.container-full .container-sub2 .container-dashboard .analysis2 .chart-base2 .bar-chart {
  width: 964px;
  height: 451px;
  margin-top: 64px;
  margin-left: 22px;
  background: white;
}

.text-menu {
  color: #5B5B6D;
  font-size: 16px;
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: none;
}

.text-menu-name {
  color: black;
  font-size: 52px;
  font-weight: bold;
  font-family: "MAIN-B";
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: none;
}

.text-content {
  color: #404252;
  font-size: 22px;
  line-height: 180%;
  word-break: keep-all;
}

.box-shadow {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}

.offcanvas {
  width: 100%;
  max-width: 500px;
}
.offcanvas textarea.form-control {
  height: auto;
}`],
})
export class PortalDashboardDeviceComponent implements OnInit {

    public category = {
        "device": "한의 건강검진",
        "blood": "혈자리",
        "herbal_medicine": "한약재",
        "medical_institution": "의료기관",
        "decoction": "탕약"
    };

    public category_ls = {
        id: "",
        title: "",
        category: "",
        content: ""
    }
    public dataset_ls = []
    public dataset_id: any;

    public labels = ['CHART 1', 'CHART 2', 'CHART 3', 'CHART 4'];
    public datas = [80, 40, 50, 50];
    public colors = ['#5041F2', '#F2748D', '#9996B7', '#DFDEF7']
    public tab = false;

    constructor(@Inject( Service)         public service: Service,@Inject( Router    )         public router: Router    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
        this.dataset_ls[0].css_class = "menu-card2";

        await this.service.render();
    }
    public deviceList = [];
    public surveyList = [];

    public async load() {
        await this.loading(true);
        this.dataset_id = this.router.url.split('/')[2];
        let { code, data } = await wiz.call("load", { "id": this.category[this.dataset_id] });
        this.dataset_ls = data.dataset_row;
        this.deviceList = this.dataset_ls.filter(item => item.datatype === '기기');
        this.surveyList = this.dataset_ls.filter(item => item.datatype === '설문');

        this.category_ls = data.dashboard_row;
        await this.service.render();
        await this.loading(false);
    }

    public click(url) {
        this.service.href(`/dashboard/device/${url}`);
    }

    public download() {
        this.tab = true;
        this.service.render();
        // $('#offcanvas-create').offcanvas('show');
    }

    public close() {
        this.tab = false;
        this.service.render();
    }

    private async loading(act) {
        this._loading = act;
        await this.service.render();
    }
}

export default PortalDashboardDeviceComponent;