import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.dataset.search');
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute, Event, NavigationEnd } from '@angular/router';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-dataset-search',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/page.dataset.search/view.scss */
.container-full {
  width: 100%;
  height: 100%;
}
.container-full .container-sub {
  width: 100%;
  background: white;
}
.container-full .container-sub .container-search {
  max-width: 1904px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: white;
  height: 160px;
  margin: auto;
}
@media (max-width: 768px) {
  .container-full .container-sub .container-search {
    width: 100%;
    height: 100px;
  }
}
.container-full .container-sub .container-search .search {
  max-width: 1904px;
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 30px;
}
@media (max-width: 768px) {
  .container-full .container-sub .container-search .search {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
  }
}
.container-full .container-sub2 {
  width: 100%;
  background: #F7F7FA;
}
.container-full .container-sub2 .card {
  padding: 0 8px;
}
.container-full .container-sub2 .container-search-list {
  flex-direction: column;
  max-width: 1780px;
  margin: auto;
  margin-top: 30px;
}
@media (max-width: 991px) {
  .container-full .container-sub2 .container-search-list {
    width: 100%;
  }
}
.container-full .container-sub2 .container-search-list .container-filter {
  background: white;
  box-shadow: 0px 3px 24px #E3E6EC;
  border-radius: 10px;
}
.container-full .container-sub2 .container-search-list .container-filter img {
  margin-top: 30px;
}
.container-full .container-sub2 .container-data-list {
  max-width: 1478px;
  border-radius: 10px;
  box-shadow: 0px 3px 24px #E3E6EC;
  margin-bottom: 90px;
}

th {
  font-size: 16px;
}

td {
  font-size: 16px;
}

.datatype {
  font-size: 16px;
  color: #4F40F1;
}

.datatext {
  font-size: 16px;
}

.title {
  font-size: 24px;
  font-family: "MAIN-B";
}

.wrap-text {
  margin-top: 1px;
  overflow: hidden;
  white-space: nowrap;
}

.keyword {
  font-size: 16px;
  font-family: "MAIN-M";
}

.btn-refresh {
  border: 0px !important;
}
.btn-refresh i {
  font-size: 20px;
}

.btn-refresh:active {
  color: #5040F1;
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(720deg);
  }
}
.rotate-animation {
  animation: rotateAnimation 0.4s linear;
}

.tag {
  border: 1px solid #B0B0E8;
  color: #B0B0E8;
  border-radius: 5px;
  cursor: default;
  font-size: 14px;
  padding: 2px;
}

.ch {
  border-radius: 10px;
  cursor: pointer;
  height: 220px;
}
.ch:hover {
  border: 3px solid #4F40F1;
}
@media (max-width: 1043px) {
  .ch {
    display: flex;
    align-items: center;
  }
  .ch .key-info {
    display: none;
  }
}
.ch .card-info {
  width: 25%;
  display: flex;
  align-items: center;
  background: #EDEEF8;
  border-radius: 0px 10px 10px 0px;
}
@media (max-width: 1200px) {
  .ch .card-info {
    width: 30%;
  }
}
@media (max-width: 1014px) {
  .ch .card-info {
    width: 35%;
  }
}
@media (max-width: 890px) {
  .ch .card-info {
    width: 38%;
  }
}
@media (max-width: 768px) {
  .ch .card-info {
    width: 40%;
  }
}
@media (max-width: 670px) {
  .ch .card-info {
    width: 45%;
  }
}
@media (max-width: 600px) {
  .ch .card-info {
    width: 50%;
  }
}
@media (max-width: 540px) {
  .ch .card-info {
    display: none;
  }
}

.box-shadow {
  box-shadow: 0px 3px 24px #E3E6EC;
}

.br-10 {
  border-radius: 10px;
}

.card:last-child {
  margin-bottom: 15px;
}

.form-select {
  border: 1px solid #707070;
  border-radius: 20px;
}

.form-label {
  font-size: 16px;
  font-family: "MAIN-M";
}

.filter-area label.form-check > * {
  display: inline-block;
  vertical-align: middle;
}

.form-check-label {
  font-size: 16px;
  font-family: "MAIN-N";
  color: #5B5D6B;
  cursor: pointer;
}

.form-check-input {
  border: 1.5px solid #5B5D6B;
  border-radius: 2px;
}

input[type=checkbox]:checked {
  background-color: #4F40F1;
}

input[type=checkbox]:checked + span.form-check-label {
  color: #4F40F1;
}

input[type=checkbox]:checked + span.form-check-label + span.ml-auto.mr-2 {
  color: #4F40F1;
}

.loading-wrap {
  display: flex;
  position: absolute;
  inset: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.08);
  z-index: 3;
}

.card-body {
  width: 100%;
}`],
})
export class PageDatasetSearchComponent implements OnInit {
    public data = [];
    public category = {
        '기기': 'device',
        '설문': 'survey',
        '혈자리': 'blood',
        '한약재': 'herbal_medicine',
        '의료기관': 'medical_institution',
        '탕약': 'decoction'
    };

    private total = 0;
    private mode = "default";
    public page = {
        start: 1,
        end: 1,
        current: 1,
    };

    public facet = {};
    public advfacet = {};

    public filter = {
        datatype: {},
        department: {},
        filetype: {},
        visibility: {},
    };

    private search = {
        text: "",
        checked: false,
    };

    private isAnimating = false;
    private _loading = false;

    constructor(@Inject( Service)         public service: Service,@Inject( Router)         private router: Router,@Inject( ActivatedRoute)         private route: ActivatedRoute,    ) { }

    public async ngOnInit() {
        await this.service.init();
        this.search.text = decodeURIComponent(location.search).split("=")[1];
        await this.pageLoad(1);

        this.router.events.subscribe(async (event: Event) => {
            if (event instanceof NavigationEnd) {
                this.search.text = decodeURIComponent(location.search).split("=")[1];
                await this.pageLoad(1);
            }
        })
        await this.service.render();
    }

    public async load() {
        await this.loading(true);

        if (this.search.text === undefined) {
            this.search.text = "";
        }
    
        let { code, data } = await wiz.call("load", {
            page: this.page.current,
            text: this.search.text,
            mode: this.mode,
            checked: JSON.stringify(this.filter),
        });
        if (code !== 200) {
            alert('ERROR!');
            return;
        }

        this.data = data.list;
        this.facet = data.facet;
        for (const key in this.facet.datatype) {
            if (this.facet.datatype[key].name === '기기') {
                this.facet.datatype[key].name = '한의 건강검진';
            }
        }
        for (let i = 0; i < this.facet.department.length; i++) {
            if (this.facet.department[i].name === null && this.facet.department[i].cnt === 0) {
                this.facet.department.splice(i, 1);
            }
        }

        for (let category in this.facet) {
            this.advfacet[category] = {};

            for (let i = 0; i < this.facet[category].length; i++) {
                let obj = this.facet[category][i];
                this.advfacet[category][obj.name] = obj.cnt;
            }
        }

        for (let i = 0; i < this.data.length; i++) {
            this.data[i].tags = JSON.parse(this.data[i].tags);
            this.data[i].created = this.data[i].created.substring(0, 10);
            this.data[i].updated = this.data[i].updated.substring(0, 10);
        }

        this.total = data.total;
        this.lastpage = data.lastpage;
        this.page.start = (parseInt((this.page.current - 1) / 10) * 10) + 1;
        this.page.end = this.lastpage;

        await this.loading(false);
        await this.service.render();
    }

    private pageLoad(p: number) {
        this.page.current = p;
        this.load();
    }

    public click(item) {
        let category = this.category[item.category];
        // this.service.href(`/dashboard/${category}/${item.id}`);
        this.service.href(`/dashboard/device/${item.id}`);
    }

    private async refresh() {
        this.isAnimating = true;

        setTimeout(async () => {
            this.isAnimating = false;
        }, 400);

        this.filter = {
            datatype: {},
            department: {},
            filetype: {},
            visibility: {},
        };
        await this.pageLoad(1);
    }

    private async loading(act) {
        this._loading = act;
        await this.service.render();
    }

    public async move(id) {
        this.service.href(`/dashboard/device/${id}`);
    }
}

export default PageDatasetSearchComponent;