import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.dashboard.link');
import { Service } from "src/libs/portal/season/service";
import { Router, Event, NavigationEnd } from '@angular/router';

@Component({
    selector: 'wiz-portal-dashboard-link',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/portal.dashboard.link/view.scss */
.container-sub {
  width: 100%;
  background: no-repeat center/100% url("/assets/images/dashboard/bubble.png");
}
.container-sub .container-image {
  width: 1780px;
  height: 480px;
  margin-left: auto;
  margin-right: auto;
}
.container-sub .container-image .content {
  height: 100%;
  width: 100%;
}
.container-sub .container-image .content .content-menu {
  padding-top: 112px;
}
.container-sub .container-image .content .content-menu-name {
  padding-top: 15px;
}
.container-sub .container-image .content .content-content {
  padding-top: 30px;
  width: 922px;
}

.text-menu {
  color: #5B5B6D;
  font-size: 16px;
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: none;
}

.text-menu-name {
  color: black;
  font-size: 52px;
  font-weight: bold;
  font-family: "dev-B";
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: none;
}

.text-content {
  color: #404252;
  font-size: 22px;
  line-height: 180%;
}`],
})
export class PortalDashboardLinkComponent implements OnInit {

    public category = {
        "device": "기기",
        "blood": "혈자리",
        "medicine": "한약재",
        "multiple": "연계"
    };

    public category_ls = {
        id: "",
        title: "",
        category: "",
        content: ""
    }
    public dataset_ls = []
    public dataset_id: any;

    constructor(@Inject( Service)         public service: Service,@Inject( Router    )         public router: Router    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
        await this.service.render();
    }

    public async load() {
        this.dataset_id = this.router.url.split('/')[2];
        let { code, data } = await wiz.call("load", { "id": this.category[this.dataset_id] });
        this.dataset_ls = data.dataset_row;
        this.category_ls = data.dashboard_row;
        await this.service.render();
    }
}

export default PortalDashboardLinkComponent;