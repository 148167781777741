import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.share.user.view');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Router } from '@angular/router';

@Component({
    selector: 'wiz-portal-share-user-view',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/portal.share.user.view/view.scss */
.form-select {
  border: 1px solid #C9C5F0;
  border-radius: 20px;
}

.btn-request {
  background-color: #E2E4F0;
  color: var(--wiz-color-navy);
  border-radius: 10px;
  border: 1px solid #E2E4F0;
  font-weight: bold;
  pointer-events: none;
}

.btn-allow {
  background-color: #64CF7C;
  color: white;
  border-radius: 10px;
  border: 1px solid #64CF7C;
  font-weight: bold;
  pointer-events: none;
}

.btn-reject {
  background-color: #F0142F;
  color: white;
  border-radius: 10px;
  border: 1px solid #F0142F;
  font-weight: bold;
  pointer-events: none;
}

.btn-process {
  background-color: #C9C5F0;
  color: black;
  border-radius: 10px;
  border: 1px solid #C9C5F0;
  font-weight: bold;
  pointer-events: none;
}

.box-shadow {
  box-shadow: 0px 3px 24px #E3E6EC;
}

.warn {
  margin: 10px 10px;
  color: red;
}

label {
  font-weight: bold;
}`],
})
export class PortalShareUserViewComponent implements OnInit {

    private mode = "default";
    public page = {
        start: 1,
        end: 1,
        current: 1,
    };

    constructor(@Inject( Service)         public service: Service,@Inject( Router    )         public router: Router    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
        await this.service.render();
    }

    public down_period = '';
    public async load() {
        let { code, data } = await wiz.call("load", { mode: this.mode, page: this.page.current });
        this.list = data.rows;
        this.down_period = data.down_period;
        this.lastpage = data.lastpage;
        this.page.start = (parseInt((this.page.current - 1) / 15) * 15) + 1;
        this.page.end = this.lastpage;

        await this.service.render();
    }

    public click(url) {
        this.service.href(`/share/view/user/${url}`);
    }

    private pageLoad(p: number) {
        this.page.current = p;
        this.load();
    }
}

export default PortalShareUserViewComponent;