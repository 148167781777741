import '@angular/compiler';
import { PortalDatasetGraphEqhlcvyeComponent } from './portal.dataset.graph.eqhlcvye/portal.dataset.graph.eqhlcvye.component';
import { PagePagesEditComponent } from './page.pages.edit/page.pages.edit.component';
import { PortalDatasetGraphElqeifhlComponent } from './portal.dataset.graph.elqeifhl/portal.dataset.graph.elqeifhl.component';
import { PortalSurveyEditComponent } from './portal.survey.edit/portal.survey.edit.component';
import { ComponentChartPieComponent } from './component.chart.pie/component.chart.pie.component';
import { PortalDatasetGraphQugbqbbvComponent } from './portal.dataset.graph.qugbqbbv/portal.dataset.graph.qugbqbbv.component';
import { PortalMacroReportGvtuwkdiComponent } from './portal.macro.report.gvtuwkdi/portal.macro.report.gvtuwkdi.component';
import { PortalShareExpertViewComponent } from './portal.share.expert.view/portal.share.expert.view.component';
import { PortalDatasetGraphGvnxalbjComponent } from './portal.dataset.graph.gvnxalbj/portal.dataset.graph.gvnxalbj.component';
import { PortalCommunityCommentComponent } from './portal.community.comment/portal.community.comment.component';
import { PortalDatasetGraphAcurqyvmComponent } from './portal.dataset.graph.acurqyvm/portal.dataset.graph.acurqyvm.component';
import { PortalDatasetGraphBdbeucfsComponent } from './portal.dataset.graph.bdbeucfs/portal.dataset.graph.bdbeucfs.component';
import { PortalSurveyListComponent } from './portal.survey.list/portal.survey.list.component';
import { PortalSurveyResultComponent } from './portal.survey.result/portal.survey.result.component';
import { PortalDetailExplanationInquireComponent } from './portal.detail.explanation.inquire/portal.detail.explanation.inquire.component';
import { PortalDatasetGraphShhssxveComponent } from './portal.dataset.graph.shhssxve/portal.dataset.graph.shhssxve.component';
import { ComponentSearchPopularityComponent } from './component.search.popularity/component.search.popularity.component';
import { ComponentSearchComponent } from './component.search/component.search.component';
import { ComponentCardNotificationComponent } from './component.card.notification/component.card.notification.component';
import { PortalCommunityViewComponent } from './portal.community.view/portal.community.view.component';
import { PortalDetailSurveyGraphComponent } from './portal.detail.survey.graph/portal.detail.survey.graph.component';
import { PortalDetailExplanationExplanationComponent } from './portal.detail.explanation.explanation/portal.detail.explanation.explanation.component';
import { PortalDatasetGraphDpljpprrComponent } from './portal.dataset.graph.dpljpprr/portal.dataset.graph.dpljpprr.component';
import { ComponentPagenationComponent } from './component.pagenation/component.pagenation.component';
import { PortalDatasetGraphIckwdgwzComponent } from './portal.dataset.graph.ickwdgwz/portal.dataset.graph.ickwdgwz.component';
import { PageShareComponent } from './page.share/page.share.component';
import { ComponentDatasetGraphComponent } from './component.dataset.graph/component.dataset.graph.component';
import { PortalShareDriveComponent } from './portal.share.drive/portal.share.drive.component';
import { ComponentCardOfferComponent } from './component.card.offer/component.card.offer.component';
import { PortalDatasetGraphJldlzqnjComponent } from './portal.dataset.graph.jldlzqnj/portal.dataset.graph.jldlzqnj.component';
import { PageSurveyComponent } from './page.survey/page.survey.component';
import { PortalDatasetGraphLpsxfshvComponent } from './portal.dataset.graph.lpsxfshv/portal.dataset.graph.lpsxfshv.component';
import { PagePagesComponent } from './page.pages/page.pages.component';
import { PortalDatasetGraphOfuzpqttComponent } from './portal.dataset.graph.ofuzpqtt/portal.dataset.graph.ofuzpqtt.component';
import { ComponentNavigationSubComponent } from './component.navigation.sub/component.navigation.sub.component';
import { PortalCommunityListComponent } from './portal.community.list/portal.community.list.component';
import { PortalDashboardMedicineComponent } from './portal.dashboard.medicine/portal.dashboard.medicine.component';
import { ComponentCardCaseComponent } from './component.card.case/component.card.case.component';
import { PageLoginComponent } from './page.login/page.login.component';
import { ComponentModalIntroduceComponent } from './component.modal.introduce/component.modal.introduce.component';
import { PageDatasetSearchComponent } from './page.dataset.search/page.dataset.search.component';
import { PortalDetailExplainationYearlyComponent } from './portal.detail.explaination.yearly/portal.detail.explaination.yearly.component';
import { ComponentNavigationComponent } from './component.navigation/component.navigation.component';
import { ComponentGraphNetworkComponent } from './component.graph.network/component.graph.network.component';
import { PortalDashboardChartBarComponent } from './portal.dashboard.chart.bar/portal.dashboard.chart.bar.component';
import { PortalCommunityEditComponent } from './portal.community.edit/portal.community.edit.component';
import { PortalDetailSurveyInquireComponent } from './portal.detail.survey.inquire/portal.detail.survey.inquire.component';
import { PortalDetailExplanationDatacardComponent } from './portal.detail.explanation.datacard/portal.detail.explanation.datacard.component';
import { PageDatasetExplanationComponent } from './page.dataset.explanation/page.dataset.explanation.component';
import { PortalSurveyAnalysisComponent } from './portal.survey.analysis/portal.survey.analysis.component';
import { PageMainComponent } from './page.main/page.main.component';
import { PageDashboardComponent } from './page.dashboard/page.dashboard.component';
import { ComponentLoadingHexaComponent } from './component.loading.hexa/component.loading.hexa.component';
import { PortalDatasetGraphUlwaytdtComponent } from './portal.dataset.graph.ulwaytdt/portal.dataset.graph.ulwaytdt.component';
import { PortalDatasetGraphTcqapgikComponent } from './portal.dataset.graph.tcqapgik/portal.dataset.graph.tcqapgik.component';
import { PortalDashboardDeviceComponent } from './portal.dashboard.device/portal.dashboard.device.component';
import { PortalMacroReportKrdhdtnbComponent } from './portal.macro.report.krdhdtnb/portal.macro.report.krdhdtnb.component';
import { PortalDetailExplanationPreviewComponent } from './portal.detail.explanation.preview/portal.detail.explanation.preview.component';
import { PageCommunityComponent } from './page.community/page.community.component';
import { PortalDashboardSurveyComponent } from './portal.dashboard.survey/portal.dashboard.survey.component';
import { PortalDashboardYearlyGraphComponent } from './portal.dashboard.yearly.graph/portal.dashboard.yearly.graph.component';
import { PortalDatasetGraphMhqcflmdComponent } from './portal.dataset.graph.mhqcflmd/portal.dataset.graph.mhqcflmd.component';
import { PortalDetailExplainationInformationComponent } from './portal.detail.explaination.information/portal.detail.explaination.information.component';
import { PortalMacroRendererComponent } from './portal.macro.renderer/portal.macro.renderer.component';
import { PortalShareUserDetailComponent } from './portal.share.user.detail/portal.share.user.detail.component';
import { PortalDashboardLinkComponent } from './portal.dashboard.link/portal.dashboard.link.component';
import { LayoutTopnavComponent } from './layout.topnav/layout.topnav.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { PortalDatasetGraphYzimkzitComponent } from './portal.dataset.graph.yzimkzit/portal.dataset.graph.yzimkzit.component';
import { ComponentChartBarLeftComponent } from './component.chart.bar.left/component.chart.bar.left.component';
import { PortalDatasetGraphEfwesxhtComponent } from './portal.dataset.graph.efwesxht/portal.dataset.graph.efwesxht.component';
import { PortalDatasetGraphOqlzxgeaComponent } from './portal.dataset.graph.oqlzxgea/portal.dataset.graph.oqlzxgea.component';
import { PortalDatasetGraphUogbpzanComponent } from './portal.dataset.graph.uogbpzan/portal.dataset.graph.uogbpzan.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PortalShareExpertDetailComponent } from './portal.share.expert.detail/portal.share.expert.detail.component';
import { PortalDetailSurveyPreviewComponent } from './portal.detail.survey.preview/portal.detail.survey.preview.component';
import { PortalShareUserViewComponent } from './portal.share.user.view/portal.share.user.view.component';
import { PortalDatasetGraphDjcqelizComponent } from './portal.dataset.graph.djcqeliz/portal.dataset.graph.djcqeliz.component';
import { PortalDetailExplainationGraphComponent } from './portal.detail.explaination.graph/portal.detail.explaination.graph.component';
import { PortalDatasetGraphQouvvjomComponent } from './portal.dataset.graph.qouvvjom/portal.dataset.graph.qouvvjom.component';
import { PageMypageComponent } from './page.mypage/page.mypage.component';
import { PageJoinComponent } from './page.join/page.join.component';
import { MatTreeModule } from '@angular/material/tree';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, COMPOSITION_BUFFER_MODE } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SortablejsModule } from "src/libs/portal/season/ngx-sortablejs";
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';

@NgModule({
    declarations: [
        AppComponent,
        PortalDatasetGraphEqhlcvyeComponent,
        PagePagesEditComponent,
        PortalDatasetGraphElqeifhlComponent,
        PortalSurveyEditComponent,
        ComponentChartPieComponent,
        PortalDatasetGraphQugbqbbvComponent,
        PortalMacroReportGvtuwkdiComponent,
        PortalShareExpertViewComponent,
        PortalDatasetGraphGvnxalbjComponent,
        PortalCommunityCommentComponent,
        PortalDatasetGraphAcurqyvmComponent,
        PortalDatasetGraphBdbeucfsComponent,
        PortalSurveyListComponent,
        PortalSurveyResultComponent,
        PortalDetailExplanationInquireComponent,
        PortalDatasetGraphShhssxveComponent,
        ComponentSearchPopularityComponent,
        ComponentSearchComponent,
        ComponentCardNotificationComponent,
        PortalCommunityViewComponent,
        PortalDetailSurveyGraphComponent,
        PortalDetailExplanationExplanationComponent,
        PortalDatasetGraphDpljpprrComponent,
        ComponentPagenationComponent,
        PortalDatasetGraphIckwdgwzComponent,
        PageShareComponent,
        ComponentDatasetGraphComponent,
        PortalShareDriveComponent,
        ComponentCardOfferComponent,
        PortalDatasetGraphJldlzqnjComponent,
        PageSurveyComponent,
        PortalDatasetGraphLpsxfshvComponent,
        PagePagesComponent,
        PortalDatasetGraphOfuzpqttComponent,
        ComponentNavigationSubComponent,
        PortalCommunityListComponent,
        PortalDashboardMedicineComponent,
        ComponentCardCaseComponent,
        PageLoginComponent,
        ComponentModalIntroduceComponent,
        PageDatasetSearchComponent,
        PortalDetailExplainationYearlyComponent,
        ComponentNavigationComponent,
        ComponentGraphNetworkComponent,
        PortalDashboardChartBarComponent,
        PortalCommunityEditComponent,
        PortalDetailSurveyInquireComponent,
        PortalDetailExplanationDatacardComponent,
        PageDatasetExplanationComponent,
        PortalSurveyAnalysisComponent,
        PageMainComponent,
        PageDashboardComponent,
        ComponentLoadingHexaComponent,
        PortalDatasetGraphUlwaytdtComponent,
        PortalDatasetGraphTcqapgikComponent,
        PortalDashboardDeviceComponent,
        PortalMacroReportKrdhdtnbComponent,
        PortalDetailExplanationPreviewComponent,
        PageCommunityComponent,
        PortalDashboardSurveyComponent,
        PortalDashboardYearlyGraphComponent,
        PortalDatasetGraphMhqcflmdComponent,
        PortalDetailExplainationInformationComponent,
        PortalMacroRendererComponent,
        PortalShareUserDetailComponent,
        PortalDashboardLinkComponent,
        LayoutTopnavComponent,
        PortalSeasonLoadingHexaComponent,
        PortalDatasetGraphYzimkzitComponent,
        ComponentChartBarLeftComponent,
        PortalDatasetGraphEfwesxhtComponent,
        PortalDatasetGraphOqlzxgeaComponent,
        PortalDatasetGraphUogbpzanComponent,
        PortalSeasonAlertComponent,
        PortalShareExpertDetailComponent,
        PortalDetailSurveyPreviewComponent,
        PortalShareUserViewComponent,
        PortalDatasetGraphDjcqelizComponent,
        PortalDetailExplainationGraphComponent,
        PortalDatasetGraphQouvvjomComponent,
        PageMypageComponent,
        PageJoinComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        NgbModule,
        SortablejsModule,
        KeyboardShortcutsModule.forRoot(),
                MatTreeModule
    ],
    providers: [{
        provide: COMPOSITION_BUFFER_MODE,
        useValue: false
    }],
    bootstrap: [AppComponent]
})
export class AppModule { }